import React from 'react'
import InstagramFeed from './InstagramFeed'
import './Footer.css'
import { MapPin, Smartphone, Mail } from 'react-feather'

export default () => (
  <div>
    {/* <h2 className="taCenter">
      Follow us{' '}
      <a href="https://instagram.com/thrivegoldcoast/">@thrivegoldcoast</a>
    </h2>
    <br />
    <InstagramFeed count="8" /> */}
    <footer className="footer">
      <div className="container taCenter address-footer">
        <div className="address-footer-section">
          <Smartphone /> <span className="footer-text">011 4657 7547</span>
        </div>
        <div className="address-footer-section">
          <Mail /> <span className="footer-text">drpoonamdutt@gmail.com</span>
        </div>
        <div className="address-footer-section">
          <MapPin />{' '}
          <span className="footer-text">
            <a target="_blank" href="https://goo.gl/maps/YQxjUFVRcRENxt9J8">
              C-230, LGF, Defence Colony, Delhi
            </a>
          </span>
        </div>
      </div>
      <div className="container taCenter powered-by-footer">
        Powered by <a href="https://www.smooper.com">Smooper</a>
      </div>
    </footer>
  </div>
)
